import React, { useState } from "react";
import "./MathscriptForm.css";
import { Link } from "react-router-dom";
import axios from "axios";

import { BsFillStarFill } from "react-icons/bs";

function MathscriptForm() {
  const [senderName, setSenderName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [selectService, setSelectService] = useState("");
  const [budget, setBudget] = useState("");
  const [selectHeard, setSelectHeard] = useState("");
  const [describeProject, setDescribeProject] = useState("");

  const [warnMessage, setWarnMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorText, setErrorText] = useState("");

  function submitMathscriptForm(e) {
    e.preventDefault();

    const message = `<h3>Name of the sender: </h3> ${senderName} </hr><br/>
    <h3>Name of the company: </h3> ${companyName} </hr><br/>
    <h3>E-mail address: </h3> ${senderEmail} </hr><br/>
    <h3>Phone number of the sender: </h3> ${senderPhone} </hr><br/>
    <h3>Selected service (if nothing written, it means WEBSITE): </h3> ${selectService} </hr><br/>
    <h3>Budget: </h3> ${budget} </hr><br/>
    <h3>Where did user hear for Mathscript (if nothing written, other person recommendation is chosen): </h3> ${selectHeard} </hr><br/>
    <h3>Message: </h3> ${describeProject} </hr><br/>
    `;

    axios
      .post("https://server.mathscript.hr/send", {
        message: message,
      })
      .then(() => {
        setWarnMessage(true);
      })
      .catch((e) => {
        setErrorMessage(true);
        setErrorText(e.message);
      });

    setSenderName("");
    setCompanyName("");
    setSenderEmail("");
    setSenderPhone("");
    setSelectService("");
    setBudget("");
    setSelectHeard("");
    setDescribeProject("");

    e.target.reset();
  }

  console.log(senderName);
  console.log(senderPhone);
  console.log(senderEmail);
  console.log(selectService);
  console.log(describeProject);

  return (
    <form className="mathscript-form" onSubmit={submitMathscriptForm}>
      <h3 className="link-controller blog-let-us-know form-h3">
        Write to us about yourself
      </h3>
      <label htmlFor="senderName">Your name</label>
      <input
        type="text"
        name="senderName"
        id="senderName"
        onChange={(e) => setSenderName(e.target.value)}
      />

      <label htmlFor="companyName">Company name</label>
      <input
        type="text"
        name="companyName"
        id="companyName"
        onChange={(e) => setCompanyName(e.target.value)}
      />

      <label htmlFor="senderEmail">
        E-mail <span>required</span>
      </label>
      <input
        type="email"
        name="senderEmail"
        id="senderEmail"
        required
        onChange={(e) => setSenderEmail(e.target.value)}
      />

      <label htmlFor="senderPhone">Mobile number</label>
      <input
        type="phone"
        name="senderPhone"
        id="senderPhone"
        onChange={(e) => setSenderPhone(e.target.value)}
      />

      <h3 className="link-controller blog-let-us-know form-h3">
        Explain to us your project
      </h3>

      <label htmlFor="selectService">
        How can we help you? <span>required</span>
      </label>

      <select
        name="selectService"
        id="selectService"
        required
        onChange={(e) => setSelectService(e.target.value)}
      >
        <option value="Website or web-application">
          Website or web-application
        </option>
        <option value="Mobile application">Mobile application</option>
        <option value="Design (UX/UI)">Design (UX/UI)</option>
        <option value="Marketing or advertising">
          Marketing or advertising
        </option>
        <option value="Other or more services together or different subject">
          Other or more services together or different subject
        </option>
      </select>

      <label htmlFor="selectHeard">
        Let us know how have you heard for us. <span>required</span>
      </label>

      <select
        name="selectHeard"
        id="selectHeard"
        required
        onChange={(e) => setSelectHeard(e.target.value)}
      >
        <option
          value="Other person's recommendation"
          onChange={(e) => setSelectHeard(e.target.value)}
        >
          Other person's recommendation
        </option>
        <option value="Facebook">Facebook</option>
        <option value="Google Ads">Google Ads</option>
        <option value="Other social networks">Other social networks</option>
        <option value="While searching in Google search bar">
          While searching in Google search bar
        </option>
        <option value="Something not mentioned here (tell us in a message)">
          Something not mentioned here (tell us in a message)
        </option>
      </select>

      <label htmlFor="budget">Your budget (not obligatory)</label>
      <input
        type="text"
        name="budget"
        id="budget"
        onChange={(e) => setBudget(e.target.value)}
      />

      <label htmlFor="describeProject">
        Describe your inquiry and repeat your e-mail again to be sure that value
        is correct <span>required</span>
      </label>
      <textarea
        name="describeProject"
        id="describeProject"
        required
        onChange={(e) => setDescribeProject(e.target.value)}
      ></textarea>

      <h3 className="link-controller blog-let-us-know form-h3">
        And last instructions before sending
      </h3>

      <p className="disclaimer">
        By clicking on the sending button, you agree to the{" "}
        <Link to="/privacy-policy">privacy policy of Mathscript</Link> and all
        its content and rules. Your data given here will be handled in
        accordance with the law, for the most part it is GDPR (General Data
        Protection Regulation). That means no other purposes than that given
        here to answer your question and demand.
      </p>
      <input type="checkbox" name="gdprOK" id="gdprOK" checked={true} />
      <p className="disclaimer">
        The answer usually follows in just a few hours.
      </p>
      <p className="disclaimer">
        If there are some issues, feel free to contact us using any of
        communication channels listed below.{" "}
      </p>
      <p className="rate-us">
        <BsFillStarFill />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us-right" />
        At the end of your answer, please do not remember to provide us with
        information on whether you were satisfied with the use of our site and
        our services. If you haven’t, feel free to briefly describe to us what
        bothered you so we can do our best to fix it in the future. Thank you so
        much.{"     "}
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
      </p>
      <button type="submit" value="SEND A MESSAGE" className="submit-button">
        SEND A MESSAGE
      </button>

      {warnMessage && (
        <h3 style={{ color: "green" }}>
          <br />
          <br />
          <br />
          Message has been sent. Please, wait for a short time and we will
          respond you. If you don't get an answer in 12 hours, send us a warning
          directly on the e-mail and we will respond quickly (in the case if
          some unpredictable situations occur).
        </h3>
      )}
      {errorMessage && (
        <h3 style={{ color: "#d82020" }}>
          <br />
          <br />
          <br />
          {errorText}
        </h3>
      )}
    </form>
  );
}

export default MathscriptForm;
