import React from "react";
import "./BlogHome.css";
import { Link } from "react-router-dom";

function BlogHome() {
  return (
    <div className="blog-home">
      <h1>Our little media corner</h1>
      <p>
        From time to time, we also publish texts on our blog from the field of
        IT sector, our internal work or anything relevant for our business area.
        We also have an internal database with guidelines on how to approach
        project development for our employees.
      </p>
      <div className="blog-home-container">
        <div className="blog-home-container-box">
          <Link to="/blog">
            Why is your business a second tier business without presence on the
            Internet nowadays?
          </Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog">How the price of a project is determined?</Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog">
            Reasons why you should not leave your project to anyone.
          </Link>
        </div>
      </div>
      <div className="blog-home-line">
        * Employees have access to the rest of the blog and internal educational
        materials
      </div>
    </div>
  );
}

export default BlogHome;
