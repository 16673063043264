import React, { useEffect } from "react";
import "./Work.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import WorkDetails from "./WorkDetails";

function Work() {
  document.title = "Work — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="work">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="From the bottom to the stars"
        middleHeader=""
        messageHeader="With many, we went through their business steps in ascending upwards. We understand big, medium and small players in the business world because even the big ones used to be small. We continue to help."
      />

      <WorkDetails />

      <GotProject />
      <Footer />
    </div>
  );
}

export default Work;
