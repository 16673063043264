import React from "react";
import "./ProjectExamples.css";

function ProjectExamples() {
  return (
    <div className="project-examples">
      <h1 className="link-controller blog-let-us-know form-h3">
        Only some selected website examples from last three months (spring
        2022.)
      </h1>
      <br />
      <br />
      <br />
      <div className="px-container">
        <div className="px" data-website="Studio FizioMaja"></div>
        <div className="px" data-website="Lucić i partneri d. o. o."></div>
        <div className="px" data-website="Vacation house Emma in Tounj"></div>
        <div className="px" data-website="Udruga Dosta je bilo"></div>
        <div className="px" data-website="Apartments Jasminka Solin"></div>
        <div className="px" data-website="Nikolić Company - vučna služba"></div>
        <div className="px" data-website="Matella Decor MP7"></div>
        <div
          className="px"
          data-website="Renata Listeš-Radnić, ovlašteni prevoditelj"
        ></div>
      </div>
    </div>
  );
}

export default ProjectExamples;
