import React from "react";
import { Link } from "react-router-dom";

import "./Navigation.css";

function Navigation() {
  return (
    <>
      <div className="navigation">
        <Link data-link="Services" to="/services">
          Services
        </Link>
        <Link data-link="About" to="/about">
          About
        </Link>
        <Link data-link="Work" to="/work">
          Work
        </Link>
        <Link data-link="Careers" to="/careers">
          Careers
        </Link>
        <Link data-link="Blog" to="/blog">
          Blog
        </Link>
        <Link data-link="Contact" to="/contact">
          Contact
        </Link>
      </div>

      <div className="small-navigation">
        <p data-link="Mathscript.">Math..</p>
        <p data-link="Mathscript.">Explore.</p>
        <p data-link="Mathscript.">Code.</p>
      </div>
    </>
  );
}

export default Navigation;
