import React from "react";
import "./PrivacyPolicyText.css";

import bubbles from "../files/bubbles.jpeg";
import rainy from "../files/rainy.jpeg";

function PrivacyPolicyText() {
  return (
    <div className="pp-text">
      <h1>Personal data protection</h1>

      <p>
        This link will provide a description of how <span>Mathscript</span> is a
        simple limited liability company for computer programming,{" "}
        <span>Savska cesta 41, 10 000 Zagreb, OIB: 47470088561</span>{" "}
        (hereinafter: Mathscript) related to the protection and processing of
        personal data. First of all, the only data we process is the contact
        information that the user leaves on this page when filling out the
        contact form, sending an e-mail or in any other way through which
        Mathscript communicates officially with users, and is listed in the
        description of available contact methods.{" "}
        <span>
          This information is used for one purpose only, and it is related to
          the needs of answering the question itself and not for any other needs
        </span>
        , including marketing activities or forwarding them to third parties.
        The same is possible only under the condition that Mathscript informs
        the user about it precisely and in detail and receives unambiguous
        consent from him that he may take actions of this form. Also, we do not
        pass on data to third or foreign countries or organisations of any type
        or group, except under the just mentioned exception.
      </p>

      <p>
        By sending an inquiry, primarily through the contact form, the user
        agrees to such data processing, knowingly and unambiguously agreeing
        with it. In case the user does not want to send a query due to the need
        to agree to such consent, or in the sent message{" "}
        <span>
          explicitly states that he does not allow the mentioned method of data
          processing, Mathscript will not be able to act upon query and will not
          respond
        </span>
        . The user is personally entitled at any time to grant another consent
        or withdraw the old one, and the acceptance of such requests will be
        notified as soon as possible, in accordance with applicable law. Also,
        by sending a verified request, the user has the right to request an
        information about all personal data related to that person, that
        Mathscript owns or processes them at a given time. It is also answered
        as soon as possible within the framework of legal regulations. Upon
        receipt of a{" "}
        <span>
          request for withdrawal of consent, Mathscript will delete, remove or
          destroy all related personal data
        </span>{" "}
        of the action seeker, so that he no longer possesses them in his
        processing.
      </p>

      <p>
        The personal data of the user is kept in a safe place and in such a way
        that it is impossible for anyone who does not have the right to access
        this data to access it, in accordance with the conditions above.
        <span>
          Mathscript will regularly maintain its administrative base and provide
          technical conditions that will guarantee the stability, security and
          confidentiality
        </span>{" "}
        of personal data and will provide any additional necessary protection to
        prevent copying, misuse, alteration or any unauthorised access to such
        data.
      </p>

      <p>
        If you access any third party site not owned by Mathscript, even through
        a link that may be listed on this site, it is{" "}
        <span>your responsibility</span> to familiarise yourself with the
        privacy policies and policies that apply to that site, as{" "}
        <span>
          Mathscript has no effect, authority, capabilities or knowledge of the
          ways in which any third party processes personal data
        </span>
        . Such pages are accessed by clicking on hyperlinks, which are marked in
        such a way that the computer mouse pointer takes the shape of the upper
        arm with an outstretched finger and in the lower left corner of the page
        on computers displays the address itself, which includes a third party
        domain.
      </p>

      <p>
        If you have any inquiry that would in any way be related to the exercise
        of your rights under laws and regulations and regulations related to the
        protection of your personal data, please contact us at any time by
        sending an inquiry to the e-mail address{" "}
        <span>mathscript@mathscript.eu</span>, which is the official address
        through which you exercise the rights just described.
      </p>

      <h1>Cookies usage</h1>

      <p>
        Cookies are small data files that are stored on the device you use, such
        as a mobile phone, tablet or computer, when you visit a place on the
        Internet, with the primary goal of ensuring that the website works
        properly. They allow the website to remember some of your actions or
        settings, so you don't have to redefine them every time you visit the
        page, which ultimately helps you, as it{" "}
        <span>
          allows you to customise the website to your user preferences and
          activities
        </span>
        . Cookies are stored for a certain period of time, whether it is days or
        months, and some can last only for a certain session. Some examples of
        settings that are most commonly remembered are username, font size,
        lighting or page theme, language settings and more. It is important to
        note that cookies are not used in any form to identify you or any other
        person, but primarily to track trends and habits when users visit our
        site.
      </p>

      <p>
        Occasionally, external services are used for statistical purposes to
        track site usage, such as <span>Google Analytics</span>. In this case,
        Google (as a third party) will send its cookies to your computer. A
        similar thing happens when using maps on Google Maps, which may at some
        point be embedded or placed on this page.
      </p>

      <p>
        Cookies can be controlled and removed as you wish, but in that case you
        should keep in mind that there is a possibility that some functions of
        this site will not be fully functional or in accordance with the
        intended purpose. Also, you may need to constantly and repeatedly
        manually adjust the settings you normally want. Most web browsers also
        allow you to automatically block cookies or delete existing ones, and
        you can do the same in the settings of each browser. How to do it in
        each browser - see the "Help" option in it or you can find instructions
        on a specialised page at www.aboutcookies.org (printed in detail, all
        the necessary guidelines). We also provide links to individual browsers,
        with instructions on how to change settings and disable third-party
        cookies: <br />
        <br />
        <a
          className="decour-link"
          href="https://support.google.com/chrome/answer/95647?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          Google Chrome
        </a>{" "}
        <br />
        <a
          className="decour-link"
          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
          target="_blank"
          rel="noreferrer"
        >
          Firefox
        </a>{" "}
        <br />
        <a
          className="decour-link"
          href="https://support.apple.com/en-us/HT201265"
          target="_blank"
          rel="noreferrer"
        >
          Safari
        </a>
      </p>

      <p>
        We use cookies on this page primarily for the purpose of tracking
        statistics on website traffic, and so that we can ultimately adapt the
        site to the wishes of users and enable the use of material in a more
        pleasant and acceptable way. Also, a{" "}
        <span>
          cookie is used to define the display of the pop-up window that pops up
          at the very bottom of the page when you first reach the pages
        </span>
        . We also use security cookies intended to prevent tampering with
        authenticity and to protect against some form of malicious attack. We
        reiterate in conclusion that you have the option to withdraw the consent
        you have given by clicking on a particular category of cookies whenever
        you wish. In case of any ambiguity, feel free to send an inquiry through
        the designated part of this website.
      </p>

      <h1>Social networks and other activities</h1>

      <p>
        Mathscript may occasionally, and especially during the holidays or
        special dates and anniversaries (whether national, international or
        internal) organise various activities aimed at selecting winners based
        on a criterion or rule of the game or competition, depending on the
        success of player during that activity. If such activities are to be
        organised, detailed explanations and rules of participation and
        selection of winners will be published through the channel through which
        such activities are organised, and primarily it is about social
        networks. All privacy policies and similar policies set forth on this
        site are fully applicable to such situations, and any deviations of
        rules should be clearly indicated, in a way that{" "}
        <span>users should be aware of them unambiguously and clearly</span>,
        with the exception of any form of manipulation. Mathscript will present
        such terms and conditions as part of this privacy policy subpage as soon
        as possible after they come into force.
      </p>

      <iframe
        className="youtube-gdpr"
        src="https://www.youtube.com/embed/acijNEErf-c"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <div className="data-day">
        <img
          src={bubbles}
          alt="Bubbles representing connections and services offered by Mathscript."
          draggable="false"
        />
        <img
          src={rainy}
          alt="Rainy and shine window representing connections and services offered by Mathscript."
          draggable="false"
        />
        <h3>CELEBRATE DATA PROTECTION DAY</h3>
        <p>28th January</p>
      </div>
    </div>
  );
}

export default PrivacyPolicyText;
