import React, { useEffect } from "react";
import "./Error.css";
import { Link } from "react-router-dom";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";

function Error() {
  document.title = "404 — Nothing found here (Mathscript)";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  function delayPreventDefault(e) {
    e.preventDefault();

    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  return (
    <div className="error">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Nothing found here"
        middleHeader="Error 404"
        messageHeader=""
      />

      <div className="error-handler">
        <h3>
          Hi there. It seems the issue is in the URL that you have typed in the
          search bar. The value <span>{window.location.href}</span> looks like
          to be nonexistent. Check if you have misspelled something
          coincidentally or maybe better — use our menu in the top right corner
          of the website to navigate through the content.
        </h3>
        <Link to="/" className="link-controller" onClick={delayPreventDefault}>
          GO TO THE HOME
        </Link>
      </div>

      <GotProject />
      <h1 className="bright-text">
        Facing any issue? Do not hesitate to let us know.
      </h1>

      <Footer />
    </div>
  );
}

export default Error;
