import React from "react";
import "./ListDescription.css";

import woodEurope from "../files/wood-europe.png";

import { RiStarSFill } from "react-icons/ri";

function ListDescription() {
  return (
    <div className="list-description">
      <h1>Even more reasons why Mathscript</h1>
      <div className="list-description-box">
        <ul className="list-description-box-1">
          <li>
            <p>
              We do not use templates or Wordpress, but we write our own code
              for each user. That makes products much faster.
            </p>
          </li>
          <li>
            <p>
              Projects created in React JS have smooth use, same as mobile
              applications. Users prefer that.{" "}
            </p>
          </li>
          <li>
            <p>
              We have years of experience in programming and we don't do that
              because we have to, but because we enjoy it.{" "}
            </p>
          </li>
          <li>
            <p>Clients rated us with a score of almost 5.0. </p>
          </li>
          <li>
            <p>
              We don't set prices strictly, but we try to listen to your
              possibilities. Joint solution can be found, always.{" "}
            </p>
          </li>
          <li>
            <p>You can get us every day, including weekend. </p>
          </li>
          <li>
            <p>
              You will not wait for an answer for days, we are quick in
              answering.{" "}
            </p>
          </li>
          <li>
            <p>
              Project development time is much faster than the competition. Our
              experience helps us cross the border.{" "}
            </p>
          </li>
          <li>
            <p>
              If you need that, it is also possible to make textual and visual
              content instead of you.{" "}
            </p>
          </li>
          <li>
            <p>
              We are not scared of new things, so you can pay us using BTC and
              some other cryptocurrencies.
            </p>
          </li>
        </ul>
        <div className="list-description-box-2 ns-inner-text">
          <img
            src={woodEurope}
            alt="Terrain fo the European Unio, map covered with wood and sample of forest, symbolising green technology and nature-friendly type of programming and other IT-jobs"
            draggable="false"
          />
          <p>
            Our services are environment-friendly and they help our only planet
            to be happier place to live for all of us
          </p>
        </div>
      </div>
    </div>
  );
}

export default ListDescription;
