import React from "react";
import "./Introduction.css";
import IntroductionBox from "./IntroductionBox";
import { whatWeDo, frameworks } from "../files/Details.js";

import { BsPatchQuestionFill } from "react-icons/bs";
import { FaReceipt } from "react-icons/fa";
import { BsFillPenFill } from "react-icons/bs";
import { MdAppRegistration } from "react-icons/md";
import { FaPaintBrush } from "react-icons/fa";
import { FaCode } from "react-icons/fa";
import { AiTwotoneBug } from "react-icons/ai";
import { IoIosRocket } from "react-icons/io";
import { MdSupportAgent } from "react-icons/md";

function Introduction() {
  return (
    <div className="introduction" id="downElement">
      <IntroductionBox
        introductionLabel="What we do in Mathscript?"
        introductionExplanation="As a young and experienced web agency, we help associates around the world find their place in the web world and stand out from the competition. Here's how we can help."
        introductionLink="/services"
      >
        <div className="long-line"></div>
        <div className="introduction-list-box">
          {whatWeDo.map((what, index) => {
            return (
              <div key={index} className="introduction-list-images">
                <img
                  src={require(`../files/${what.whatSrc}.jpeg`)}
                  alt={what.whatAlt}
                  draggable="false"
                />
                <p>{what.whatText}</p>
              </div>
            );
          })}
        </div>
      </IntroductionBox>

      <IntroductionBox
        introductionLabel="What technologies do we use?"
        introductionExplanation="We strive to be different from others. Therefore, we use the latest technologies and monitor their updates on a daily basis. We do not use slow and frivolous templates, we create projects by writing code for each user. Here's how."
        introductionLink="/about"
      >
        <div className="long-line"></div>

        <div className="introduction-list-box">
          {frameworks.map((fw, index) => {
            return (
              <div key={index} className="introduction-list-images">
                <img
                  src={require(`../files/${fw.fwSrc}`)}
                  alt={fw.fwAlt}
                  draggable="false"
                  className="no-cover radius"
                />
                <p>{fw.fwText}</p>
              </div>
            );
          })}
        </div>
      </IntroductionBox>

      <IntroductionBox
        introductionLabel="How to arrange cooperation with us?"
        introductionExplanation="If you have decided to step forward, we would be glad if you would choose us. We treat each collaborator equally and with respect. Unlike others, we will be happy to take your wishes into account and try to adapt to them."
        introductionLink="/work"
      >
        <div className="long-line"></div>

        <div className="introduction-list-box">
          <div className="inner-slider2">
            <BsPatchQuestionFill className="icon-setter" />
            <p>1. Let's get in touch</p>
          </div>
          <div className="inner-slider2">
            <FaReceipt className="icon-setter" />
            <p>2. We agree to cooperate with you</p>
          </div>
          <div className="inner-slider2">
            <BsFillPenFill className="icon-setter" />
            <p>3. We record how you want your project to look like</p>
          </div>
          <div className="inner-slider2">
            <MdAppRegistration className="icon-setter" />
            <p>
              4. We prepare a development plan and provide a duration framework
            </p>
          </div>
          <div className="inner-slider2">
            <FaPaintBrush className="icon-setter" />
            <p>5. Designing starts</p>
          </div>
          <div className="inner-slider2">
            <FaCode className="icon-setter" />
            <p>6. We programme and code</p>
          </div>
          <div className="inner-slider2">
            <AiTwotoneBug className="icon-setter" />
            <p>7. We do debugging and testing to make your project perfect</p>
          </div>
          <div className="inner-slider2">
            <IoIosRocket className="icon-setter" />
            <p>
              8. We connect domain and hosting and check everything with you
            </p>
          </div>
          <div className="inner-slider2">
            <MdSupportAgent className="icon-setter" />
            <p>9. After all, you still have our full support</p>
          </div>
        </div>
      </IntroductionBox>
    </div>
  );
}

export default Introduction;
