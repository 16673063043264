import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { useState, useEffect } from "react";

import Home from "./components/Home";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Careers from "./components/Careers";
import Services from "./components/Services";
import Work from "./components/Work";
import About from "./components/About";
import Error from "./components/Error";

function App() {
  const [readyToShow, setReadyToShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReadyToShow(true);
    }, 4000);
  }, []);

  return (
    <div className="App">
      <Router>
        <div className={readyToShow ? "splash compress" : "splash"}>
          <img
            src={require("./files/favicon.png")}
            alt="Logo - Mathscript, computer programming - web, websites, web design, webshops and more digital services"
          />
          <h1>Mathscript</h1>
          <h3>Shaping the digital Earth</h3>
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/services" element={<Services />} />
          <Route path="/work" element={<Work />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Error />} />
        </Routes>

        <CookieConsent
          location="bottom"
          buttonText="Accept cookies and let's go"
          cookieName="mathscript-tracker-ntc-cookie"
          style={{
            background: "rgba(0, 0, 0, 0.8)",
            zIndex: 2000000,
            height: "fit-content",
            display: "grid",
            placeContent: "center",
            placeItems: "center",
            boxShadow: "0 0 30px white",
            padding: "3rem 4rem",
            textAlign: "center",
            lineHeight: "1.4",
            fontSize: "clamp(var(--clamp-xs-text))",
            boxDecorationBreak: "clone",
            userSelect: "none",
          }}
          buttonStyle={{
            color: "white",
            backgroundColor: "#d82020",
            borderRadius: "20px",
            padding: "0.5rem 1rem",
            boxShadow: "0 0 10px white",
            fontWeight: "bold",
          }}
          expires={30}
        >
          Hi there and welcome to Mathscript. We are programming and coding
          agency that shapes digital Earth and business into a new future.
          Before you start exploring us and, hopefully, decide to cooperate with
          us, we have to familiarise you with cookies. You have seen them a
          thousand times so far and already grasp they represent small documents
          which intent is not to spy on you, but help us give you much better
          user experience. For this reason, we prepare for you{" "}
          <Link
            to="/privacy-policy"
            style={{
              color: "white",
              backgroundColor: "#d82020",
              borderRadius: "20px",
              textDecoration: "none",
              paddingInline: ".3rem",
              boxDecorationBreak: "clone",
            }}
          >
            privacy policy terms
          </Link>{" "}
          that you can read. We will stay at disposal if anything is not clear
          enough, ask freely. Now, click the button and let's do some serious
          work.
        </CookieConsent>
      </Router>
    </div>
  );
}

export default App;
