export const whatWeDo = [
  {
    whatText: "WEBSITES",
    whatSrc: "website-a",
    whatAlt: "Website design - Mathscript agency",
  },

  {
    whatText: "WEB-APPS",
    whatSrc: "webapp-a",
    whatAlt: "Web-app design - Mathscript agency",
  },

  {
    whatText: "WEB-SHOPS",
    whatSrc: "webshop-a",
    whatAlt: "Web-shop design - Mathscript agency",
  },

  {
    whatText: "MOBILE APPS",
    whatSrc: "mobile-app",
    whatAlt: "Mobile apps - Mathscript agency",
  },

  {
    whatText: "DESIGN (UX/UI)",
    whatSrc: "design-a",
    whatAlt:
      "Mathscript - designing logos, images, drawings, videos. UX / UI design.",
  },

  {
    whatText: "CONTENT CREATION INSTEAD OF USER",
    whatSrc: "content-create-a",
    whatAlt:
      "Creating content for websites and applications instead of users - Mathscript agency",
  },

  {
    whatText: "CMS",
    whatSrc: "cms-a",
    whatAlt:
      "Development of CRM for self-control of the website - Mathscript agency",
  },

  {
    whatText: "SEO — FOR BETTER PLACE IN GOOGLE",
    whatSrc: "seo-a",
    whatAlt:
      "Mathscript agency offers SEO maintenance for website and web services, positioning the website in a better place on Google",
  },

  {
    whatText: "DOMAIN AND HOSTING",
    whatSrc: "hosting-a",
    whatAlt:
      "Domain lease and hosting offer, convenient and without the need for the user to take care of the maintenance of the website - Mathscript agency",
  },

  {
    whatText: "MARKETING AND SMART ADVERTISING",
    whatSrc: "social-media-a",
    whatAlt:
      "Mathscript agency provides marketing services and affordable advertising of Internet and user websites, as well as advertising on Facebook and Google, general advertising on social networks",
  },

  {
    whatText: "SOCIAL NETWORKS PROFILES MAINTENANCE",
    whatSrc: "sm-a",
    whatAlt:
      "Mathscript agency - running social networks instead of users, good price so that users have less worries",
  },

  {
    whatText: "GRAMMAR-CHECK IN ENGLISH",
    whatSrc: "lecturing-a",
    whatAlt:
      "Mathscript agency - in addition to creating web pages, we also offer proofreading and proofreading services in English and Croatian, possible and affordable translation from Croatian to English and vice versa by agreement",
  },
];

export const frameworks = [
  {
    fwText: "REACT JS (frontend)",
    fwSrc: "reactjs.png",
    fwAlt: "Mathscript agency - web design in React JS for frontend",
  },

  {
    fwText: "NODE JS (runtime-environment for Javascript)",
    fwSrc: "nodejs.jpeg",
    fwAlt: "Mathscript agency - web design in Node JS",
  },
  {
    fwText: "EXPRESS JS (backend)",
    fwSrc: "expressjs.jpeg",
    fwAlt:
      "Mathscript agency - web design in Express JS for the server - backend",
  },
  {
    fwText: "MONGO DB (databases)",
    fwSrc: "mongodb.png",
    fwAlt:
      "Mathscript agency - Mongo DB is used to create the database, namely the Atlas cloud database - optimization is carried out for better prices",
  },
  {
    fwText: "JAVASCRIPT — JS (programming language)",
    fwSrc: "js.png",
    fwAlt:
      "Javascript is one of the most popular and useful programming languages in the world, it is used basically in every web-browser",
  },

  {
    fwText: "REACT NATIVE — MOBILE APPS",
    fwSrc: "react-native.png",
    fwAlt:
      "React Native is used to built mobile applications, both Android and iOS. Using it, there is no need to have separate development in Android Studio with Java or Kotlin on one side and Swift on the other side",
  },

  {
    fwText: "SCSS — SASS (styling)",
    fwSrc: "sass.jpeg",
    fwAlt:
      "Mathscript Agency uses SCSS - SASS as a more advanced version of CSS with greater styling capabilities for frontend components",
  },

  {
    fwText: "HTML and CSS (styling)",
    fwSrc: "html-css.png",
    fwAlt:
      "Mathscript agency - HTML is mostly replaced by JSX in React, while CSS is used in parallel with SCSS",
  },
  {
    fwText: "FIGMA",
    fwSrc: "figma.png",
    fwAlt:
      "Mathscript agency - when creating web pages, we design through the Figma tool - less often through Adobe XD, close to Adobe Illustrator or Photoshop. Graphic design.",
  },
  {
    fwText: "FACEBOOK ADS, GOOGLE ADS",
    fwSrc: "fb-ads.png",
    fwAlt:
      "Mathscript agency - in addition to creating websites, we also offer advertising through the Facebook platform. Advanced and smart advertising. Favorable, quality, reliable results, optimal price-quality ratio. Possibility of advertising through Google - Google Ads. Favorable, quality, reliable results, optimal price-quality ratio.",
  },
  {
    fwText: "TENSORFLOW JS (artificial intelligence)",
    fwSrc: "tf.png",
    fwAlt:
      "Mathscript agency - Tensorflow JS, a tool for applying artificial intelligence to projects with Javascript as a database",
  },
];
