import React from "react";
import "./GotProject.css";
import { Link } from "react-router-dom";

function GotProject() {
  function delayPreventDefault(e) {
    e.preventDefault();

    setTimeout(() => {
      window.location.href = "/contact";
    }, 1000);
  }

  return (
    <div className="got-project">
      <h1>Got a project or an idea?</h1>
      <p>
        If you have decided to make a step forward in your business, we listen
        to you. If you have any doubts, need advice or we can help you in some
        other way, we are waiting for your inquiry.{" "}
      </p>
      <Link
        to="/contact"
        className="link-controller"
        onClick={delayPreventDefault}
      >
        LET'S GET STARTED
      </Link>
    </div>
  );
}

export default GotProject;
