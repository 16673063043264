import React from "react";
import "./SomeBenefits.css";
import btc from "../files/bitcoin-logo.png";
import highFive from "../files/high-five.jpeg";
import volunteer from "../files/volunteer.jpeg";

function SomeBenefits() {
  return (
    <div className="some-benefits-over">
      Some of interesting benefits
      <div className="some-benefits">
        <div className="some-benefits-box">
          <h1>PAY WITH CRYPTO</h1>
          <h3>1 MIN</h3>
          <span>AFTER YOU RECEIVE MAIL</span>
          <img
            src={btc}
            alt="Bitcoin and other cryptocurrencies are acceptable way of paying in Croatian IT company Mathscript, only one in Croatia and Zagreb"
            draggable="false"
          /> 
          <p>
            It is possible to pay in about ten cryptocurrencies. Then it is
            necessary to pay the entire amount at once and within 15 minutes of
            receiving instructions as agreed.
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>BRING US A CLIENT AND GET MONEY</h1>
          <h3>8 %</h3>
          <span>OF PROJECT VALUE GOES TO YOUR WALLET</span>
          <img
            src={highFive}
            alt="Mathscript has action which can give 10 % of project value to person who brings us a new client or user"
            draggable="false"
          />
          <p>
            If you recommend your friend, he takes the service and we
            successfully complete the project, after payment we pay you 8 % of
            the value on your account, or 5 % if the value is above 1000 EUR
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>NGO OR COMPANY YOUNGER THAN 3 MONTHS?</h1>
          <h3>— 10 %</h3>
          <span>DISCOUNT ON THE TOTAL PRICE</span>
          <img
            src={volunteer}
            alt="Non-governmental organisations, political parties, associations and companies (firms) that started to work (were registered officially) in a period less than three months ago - get 10 % of discount"
            draggable="false"
          />
          <p>
            Non-profit organisations, political parties, associations and
            companies that started to work — meaning they were registered
            officially in a period less than three months ago - get 10 % of
            discount
          </p>
        </div>
      </div>
    </div>
  );
}

export default SomeBenefits;
