import React from "react";
import "./Services.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import ServicesDetails from "../components/ServicesDetails";

function Services() {
  document.title = "Services — Mathscript";

  return (
    <div className="services">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Turning an idea into reality"
        middleHeader=""
        messageHeader="We may not know each other yet, but the first thing you need to know is that we love our job. Here is how we can transfer that into your business and make it stands out."
      />

      <ServicesDetails />

      <GotProject />
      <Footer />
    </div>
  );
}

export default Services;
