import React from "react";
import "./ContactMoreData.css";

import { MdMarkEmailRead } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { BsFacebook } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { GrLinkedin } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { FaViber } from "react-icons/fa";

function ContactMoreData() {
  return (
    <div className="contact-more-data">
      <h3 className="link-controller blog-let-us-know form-h3">
        More ways to reach us
      </h3>
      <div className="more-contacts">
        <a
          href="mailto:mathscript@mathscript.hr"
          target="_blank"
          rel="noreferrer"
        >
          <MdMarkEmailRead className="more-contact-icon" />
          <p>
            mathscript
            <br />
            @mathscript.eu
          </p>
        </a>
        <a href="mailto:support@mathscript.hr" target="_blank" rel="noreferrer">
          <HiOutlineMail className="more-contact-icon" />
          <p>
            support
            <br />
            @mathscript.eu
          </p>
        </a>
        <a
          href="https://www.facebook.com/MathscriptEU/"
          target="_blank"
          rel="noreferrer"
        >
          <BsFacebook className="more-contact-icon" />
          <p>Facebook</p>
        </a>
        <a
          href="https://www.instagram.com/mathscript.eu/?hl=en"
          target="_blank"
          rel="noopener"
        >
          <GrInstagram className="more-contact-icon" />
          <p>Instagram</p>
        </a>
        <a
          href="https://www.linkedin.com/in/mathscript-web-development-agency-106930232/"
          target="_blank"
          rel="noreferrer"
        >
          <GrLinkedin className="more-contact-icon" />
          <p>LinkedIn</p>
        </a>
        <a
          href="https://twitter.com/MathscriptEU"
          target="_blank"
          rel="noreferrer"
        >
          <BsTwitter className="more-contact-icon" />
          <p>Twitter</p>
        </a>
        <a
          href="viber://chat?number=00385977656906"
          target="_blank"
          rel="noreferrer"
        >
          <FaViber className="more-contact-icon" />
          <p>Viber (message)</p>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=00385977656906"
          target="_blank"
          rel="noreferrer"
        >
          <BsWhatsapp className="more-contact-icon" />
          <p>Whatsapp (message)</p>
        </a>
      </div>
    </div>
  );
}

export default ContactMoreData;
