import React from "react";
import "./WorkDetails.css";
import LastProjects from "../components/LastProjects";
import ProjectExamples from "../components/ProjectExamples";

function WorkDetails() {
  return (
    <div className="wd">
      <h1 className="link-controller blog-let-us-know form-h3">
        Why is it crucial today to be digitised?
      </h1>
      <div className="wd-crucial-over">
        <div className="wd-crucial">
          <h1>Almost 80 %</h1>
          <h3>of people buy products after searching on the Internet</h3>
          <hr className="wd-line" />
          <p>
            Basically, that means you immediately lose majority of potential
            customers who cannot find you and additionally you lose confidence
            in the eyes of people who are used to do everything online. It is a
            colossal loss of money.{" "}
          </p>
        </div>

        <div className="wd-crucial">
          <h1>More than 60 %</h1>
          <h3>
            of people won't even consider you as a seller without online
            presence
          </h3>
          <hr className="wd-line" />
          <p>
            People today don’t think the same as they did ten years ago.
            Businesses that do not adapt to this fail. Researches show that
            people would not buy a product from a well-known company if they did
            not have a website. For smaller companies, it is even more
            devastating.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>About 60 to 70 %</h1>
          <h3>of small businesses have a website</h3>
          <hr className="wd-line" />
          <p>
            So, if you make a progress and move yourself from this negative
            statistics, you are already in better position than at least third
            of your competitors. If you resist changes, you become minority in
            business world and you leave many people to others. Do not let your
            work to get marginalised without need.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>At least 50 %</h1>
          <h3>of subjects without web think about get one</h3>
          <hr className="wd-line" />
          <p>
            Even the minority that has not yet fully digitalised their business
            is aware of the limitations and dangers it carries and even among
            them the prevailing decision is that they need to change this
            urgently. Don't worry, you will easily agree with us because we also
            adapt to you. Check it now.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>About one third</h1>
          <h3>of CEOs consider all their work too small to have a web</h3>
          <hr className="wd-line" />
          <p>
            And they are taking the wrong step in the beginning. Once a bad
            reputation is reached, trust is hard to regain. Mathscript keeps in
            mind that not all companies are big and we have acceptable solutions
            even if others do not already have them. Check now, it is really not
            important how small you are.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Three seconds</h1>
          <h3>
            is the time in which user decides to stay or leave your site and
            buying
          </h3>
          <hr className="wd-line" />
          <p>
            For every second of no upload, sales conversion drops by more than
            10 %. What is worser is that over 60 % of people will leave your
            site if the load takes more than three seconds and they do not want
            to come back. That is why it is important that your site is modern
            and maintained periodically in order to adapt to the time and
            people.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>More than 90 %</h1>
          <h3>of customers will rate you better if you just have a web</h3>
          <hr className="wd-line" />
          <p>
            People will have a more positive opinion and impression of a job
            only if they have a presence on the web. This is logical because
            each of us today searches for things on the Internet first. More or
            less, all of us will get a bad impression of the business if the
            entity does not exist on the Internet. This creates the impression
            of secrecy and bad business and therefore it should be avoided
            urgently.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>At least 95 %</h1>
          <h3>of western people will buy everything online in 20 years</h3>
          <hr className="wd-line" />
          <p>
            The trends are clear and we are in a time that cannot be stopped.
            Indeed, Mathscript is one of the few companies that has recognised
            this fact and adjusted its offer even for small entities, often
            neglected. To show you that, we are waiting for your move.
          </p>
        </div>
      </div>
      <h1 className="link-controller blog-let-us-know form-h3">
        There are even more statistics — read carefully
      </h1>
      <p className="wd-stats">
        Few people know that just adding a blog to a page changes things
        drastically. Namely, sites with regular blog maintenance at a moderate
        pace (one post every two days or similar) have{" "}
        <span>over 95 % more links to themselves</span>. Although in the
        beginning every expense seems big and unsustainable, in the long run it
        pays off and will come back through much more pronounced earnings from
        higher turnover.
      </p>
      <p className="wd-stats">
        Regular blogging in the way described in the previous paragraph also
        affects business. Estimates show that in this case, companies show on
        average{" "}
        <span>over three times more business than those that do not</span>. So,
        although they saved money in the beginning, jobs without a blog are
        doomed to long-term losses and a greater chance of failure. Smart
        decisions should be done in the beginning.
      </p>
      <p className="wd-stats">
        A significant portion of the pages are of poor quality. For example,
        contacts are missing on the home page, losing a significant number of
        potential customers. Furthermore, the sites are often unmaintained and
        older in appearance, and a significant percentage do not adapt to cell
        phones and tablets. If we take into account that the majority of traffic
        on the Internet takes place via mobile phones, all these things
        significantly impair the quality of your work.{" "}
        <span>
          Set up a professional site that someone maintains 24 hours a day
        </span>
        .
      </p>
      <p className="wd-stats">
        People are often afraid to have a site because more attention could
        expose them to negative criticism due to the larger number of people and
        the anonymity of the online world. However, it is estimated that about
        90 % of service comments are positive, which is really easily confirmed
        by reviewing the reviews of most entities, especially small and medium
        ones. <span>Courage is the main trait of an entrepreneur</span>. After
        all, a larger influx of people is what you want for your business,
        right?
      </p>
      <p className="wd-stats">
        More than 70 % of people prefer{" "}
        <span>
          online shopping because they believe it is easier, simpler, more
          practical and there is a greater possibility of influencing the price
        </span>{" "}
        than in physical outlets. That is why users of telecommunications
        services prefer to resolve disputes in indirect ways.
      </p>
      <p className="wd-stats">
        The beauty of people is in diversity. A large number of people are shy
        or uncomfortable when negotiating directly. Just imagine how many
        potential customers{" "}
        <span>
          wanted to buy something you were selling, but gave up because you
          didn’t have an online sales channel
        </span>
        . We didn't find any statistics for this, but you got it. An online
        presence expands your potential customer base drastically.
      </p>
      <p className="wd-stats">
        <span>2 000 000 000</span>. This enormous number is the number of
        websites currently in the world and it is growing every second. Growth
        of mobile apps number or other more complex software solutions is much,
        but much higher, even more fascinating. You have literally no reason or
        justification to harm your business, no matter how small or large.
      </p>
      <h1 className="link-controller blog-let-us-know form-h3">
        Why do customers recognise us as trusted associates?
      </h1>
      <div className="ns-inner change-back">
        <div className="ns-inner-text">
          <h1>20+</h1>
          <h3>PROJECTS</h3>
          <span></span>
          <p>Only as a Mathscript, excluding other experience</p>
        </div>
        <div className="ns-inner-text">
          <h1>5+</h1>
          <h3>YEARS OF EXPERIENCE</h3>
          <span></span>
          <p>
            Multi-year workers experience and education in programming, before
            we started Mathscript
          </p>
        </div>
        <div className="ns-inner-text">
          <h1>4.8</h1>
          <h3>AVERAGE USER RATING</h3>
          <span></span>
          <p>Multi-year workers' experience and education in programming</p>
        </div>
        <div className="ns-inner-text">
          <h1>120+</h1>
          <h3>HOURS OF YEARLY EDUCATION</h3>
          <span></span>
          <p>
            Number of hours per worker during working time in one year period
          </p>
        </div>
      </div>
      <p className="wd-stats">
        After finishing every project, we like to listen to our client and ask
        him/her was cooperation with us good. We try to collect every remark and
        critics and <span>fix it in the next project making process</span>. This
        is how we grow as an agency and become better and better from project to
        project. We are grateful for every sentence we received because it made
        us more serious after every day.
      </p>

      <ProjectExamples />
      <LastProjects />

      <h4>
        In Mathscript, we do not impose, but cooperate with clients. They
        recognise that and value us highly. That is one of the reasons we always
        find a common solution. We approach both small and large ones with equal
        effort and we want to be recognised for that. Let us dig into your
        project.
      </h4>
    </div>
  );
}

export default WorkDetails;
