import React, { useEffect } from "react";
import "./Careers.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import CareersDetails from "../components/CareersDetails";

function Careers() {
  document.title = "Careers — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="careers">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Shape your future"
        middleHeader=""
        messageHeader="When you do what you love, you really enjoy life. If it is not the case, change it. Meet our working community."
      />

      <CareersDetails />

      <Footer />
    </div>
  );
}

export default Careers;
