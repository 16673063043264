import React, { useEffect } from "react";
import "./CanvasDraw.css";
import { ImArrowDown } from "react-icons/im";

import { FaAppStoreIos } from "react-icons/fa";
import { GiWorld } from "react-icons/gi";
import { GrReactjs } from "react-icons/gr";
import { MdWebStories } from "react-icons/md";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { GiCubes } from "react-icons/gi";

function CanvasDraw(props) {
  let downElement;
  useEffect(() => {
    downElement = document.getElementById("downElement");
  }, []);

  return (
    <div className="canvas-draw">
      <div className="canvas-div">
        {/*  <div className="videoover">
          <video
            className="videopl"
            src={require("../files/videoearth.mp4")}
            autoPlay
            muted
            loop
          ></video>
          <div className="videodiv"></div>
        </div> */}
        <h1>{props.header}</h1>
        {/* <h3>{props.middleHeader}</h3> */}
        <p>{props.messageHeader}</p>

        <div className="circles">
          <div className="circle">
            <FaAppStoreIos className="circle-icon" />
            <p>WEB APPLICATIONS</p>
          </div>

          <div className="circle">
            <GrReactjs className="circle-icon" />
            <p>CUSTOM SOFTWARE</p>
          </div>

          <div className="circle">
            <MdWebStories className="circle-icon" />
            <p>WEBSITE CREATION</p>
          </div>

          <div className="circle">
            <BsFillCreditCard2BackFill className="circle-icon" />
            <p>E-COMMERCE</p>
          </div>

          <div className="circle">
            <GiCubes className="circle-icon" />
            <p>DESIGN AND MARKETING</p>
          </div>

          <div className="circle">
            <GiWorld className="circle-icon" />
            <p>DIGITISING COMPANIES</p>
          </div>
        </div>
      </div>
      {/*       {props.toShow && <div className="world"></div>}
       */}
      {props.toShow && (
        <div className="card-overlay">
          <h3>What sets us apart from the rest?</h3>
          <p>
            We started coding, programming and IT activities many years ago.
            Today, we are a mature team that can create for users literally
            anything they want and imagine.
          </p>

          <div className="cards">
            <div className="card">
              <div className="front">
                <h3> ONLY "CUSTOM" PRODUCTS</h3>
                <p>
                  We're not one of those people who use copy and templates that
                  are cheap, slow and give your business a frivolous reputation.
                  We write code professionally, from scratch — for everyone.
                </p>
              </div>
              <div className="back">
                <p>
                  We will create your future product professionally, from the
                  creation of the plan, through the design, all the way to
                  writing the programming code and basic SEO-optimisation to
                  make your page noticeable to Google. Your product is therefore
                  unique and stands out from the competition.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>MERN AND SPA-TECHNOLOGIES MAKE YOUR PRODUCT FASTER</h3>
                <p>
                  The technologies we use will make your product something
                  different from most others. It will be faster and due to
                  special "routing", users will have the impression of smooth
                  use, as if they were using an application on their mobile
                  phone instead of the web.
                </p>
              </div>
              <div className="back">
                <p>
                  Keep in mind that the vast majority of users today search the
                  Internet via mobile phones. Therefore, your potential clients,
                  because of the impression that they are using a mobile
                  application instead of the web, would create a more positive
                  impression of you and ultimately this is reflected in the
                  finances of your company.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>PROFESSIONALISM AND SERIOUSNESS</h3>
                <p>
                  A large number of clients have great difficulties because they
                  hire bad "developers" who do their site incompletely and later
                  do not respond and therefore suffer losses. Your company is a
                  part of your life and entrust it only to those who will do the
                  job not well, but perfectly.
                </p>
              </div>
              <div className="back">
                <p>
                  Mathscript is a professional agency made up of people with
                  many years of education in software development. We will make
                  your product carefully, neatly and even ask you for a final
                  confirmation, so that we can adapt the additional solution to
                  you. You will see for yourself after sending us a message.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>AVAILABILITY EVERY DAY FOR YOUR INQUIRIES</h3>
                <p>
                  We understand that digital services are essential, perhaps
                  even crucial, for your business. Therefore, if you need an
                  upgrade, change, supplement or report something else, we will
                  be at your disposal every day. You will not be "left alone"
                  for a single day.
                </p>
              </div>
              <div className="back">
                <p>
                  One of the major objections to IT companies is the working
                  hours and unavailability on many days, especially on weekends,
                  which most users consider unprofessional. That's why we're
                  there for you even on days when most others aren't, and that's
                  because we love our job and it's not a burden to us.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>DON'T BE AFRAID OF DIGITAL SERVICES BECAUSE OF PRICES</h3>
                <p>
                  Digital and IT services, according to a TV joke, have a
                  reputation for services with astronomical prices. That's not
                  the case, of course. Mathscript adapts offers to both large
                  and small and does not offer services only to one or the
                  other. It's best to send a message and see for yourself.
                </p>
              </div>
              <div className="back">
                <p>
                  There is not a single reason to keep your business non-digital
                  in the 21st century. We are in the digital age and Mathscript
                  has recognized the needs of all market participants. Don't let
                  the fear of price hold you back, contact us and we will find a
                  joint solution that will modernise the appearance of your
                  business in front of your customers.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>
                  MONEY FOR THE CLIENT, DISCOUNT FOR ASSOCIATIONS,
                  CRYPTOCURRENCIES...
                </h3>
                <p>
                  Mathscript has a whole range of benefits and special
                  promotions. One of them is that we will deposit money into
                  your account if you just recommend a client to us. We also
                  grant a discount to non-profit organisations without exception
                  as a form of support for civil society. Let's also highlight
                  that you can pay us in cryptocurrencies!
                </p>
              </div>
              <div className="back">
                <p>
                  All you have read are just some of the benefits. For example,
                  during the holiday season, we can also have special prizes for
                  our current clients, include clients in prize games at the
                  European level in which they can receive monetary incentives
                  for the best website and similar things. In any case — we
                  believe you will be satisfied.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.toShow && (
        <div
          className="motto-canvas"
          beforeName="Upoznaj nas malo bolje"
          onClick={() => downElement.scrollIntoView(true)}
        >
          Move your bussiness a step forward{" "}
          <ImArrowDown className="motto-icon" />
        </div>
      )}
    </div>
  );
}

export default CanvasDraw;
