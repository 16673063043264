import React, { useState } from "react";
import "./SpreadMenu.css";
import { Link } from "react-router-dom";

import mathscript from "../files/favicon.png";

function SpreadMenu() {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <div className="spreadmenu">
        {isShown ? "" : <h3>Explore Mathscript &#x02008; &#x02ABC;</h3>}
        <img
          src={mathscript}
          alt="Logo of Mathscript Ltd in Zagreb, Croatia, the European Union"
          draggable="false"
          onClick={() => setIsShown(!isShown)}
        />
      </div>
      {/* <div
        className={
          isShown
            ? "spreaded-content-layer-between show"
            : "spreaded-content-layer-between"
        }
      >
        <div className="spreaded-content" onClick={() => setIsShown(false)}>
          <img
            src={mathscript}
            alt="Logo of Mathscript Ltd in Zagreb, Croatia, the European Union"
            draggable="false"
            onClick={() => setIsShown(false)}
          />
          <Link to="/">Home</Link>
          <Link to="/services">Services</Link>
          <Link to="/about">About</Link>
          <Link to="/work">Work</Link>
          <Link to="/careers">Careers</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/contact">Contact</Link>
          <a href="https://mathscript.hr" className="english">
            {" "}
          </a>
        </div>
      </div> */}
      <div className={isShown ? "spread-cont blow" : "spread-cont"}>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
      </div>
      <div className={isShown ? "real-cont blow" : "real-cont"}>
        <img
          src={mathscript}
          alt="Logo of Mathscript Ltd in Zagreb, Croatia, the European Union"
          draggable="false"
        />
        <h1>MATHSCRIPT</h1>
        <h3>Computer programming agency</h3>
        <div className="org-link">
          <Link to="/">Home</Link>
          <Link to="/services">Services</Link>
          <Link to="/about">About</Link>
          <Link to="/work">Work</Link>
          <Link to="/careers">Careers</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/contact" className="special">
            Contact
          </Link>
          <a href="https://mathscript.hr" className="english">
            {" "}
          </a>
        </div>
      </div>
    </>
  );
}

export default SpreadMenu;
