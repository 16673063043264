import React from "react";
import "./NewStandards.css";

function NewStandards() {
  return (
    <div className="new-standards">
      <h3>Experience and commitment </h3>
      <div className="ns-inner">
        <div className="ns-inner-text">
          <h1>20+</h1>
          <h3>PROJECTS</h3>
          <span></span>
          <p>Only as a Mathscript, excluding other experience</p>
        </div>
        <div className="ns-inner-text">
          <h1>5+</h1>
          <h3>YEARS OF EXPERIENCE</h3>
          <span></span>
          <p>
            Multi-year workers experience and education in programming, before
            we started Mathscript
          </p>
        </div>
        <div className="ns-inner-text">
          <h1>4.8</h1>
          <h3>AVERAGE USER RATING</h3>
          <span></span>
          <p>
            Our customers know to award our commitment. Let us help you and your
            organisation, too.
          </p>
        </div>
        <div className="ns-inner-text">
          <h1>120+</h1>
          <h3>HOURS OF YEARLY EDUCATION</h3>
          <span></span>
          <p>
            Number of hours per worker during working time in one year period
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewStandards;
