import React from "react";
import "./ContactButton.css";
import { Link } from "react-router-dom";

function ContactButton() {
  return (
    <div className="cb-over">
      <Link to="/contact">
        <div className="contact-button">Got a project?</div>{" "}
      </Link>
    </div>
  );
}

export default ContactButton;
