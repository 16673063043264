import React, { useEffect, useRef } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import SpreadMenu from "../components/SpreadMenu";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";

import { ImWarning } from "react-icons/im";

function Blog() {
  document.title = "Blog — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const modalRef = useRef();

  function openModalWarn() {
    modalRef.current.classList.add("show-modal");
    setTimeout(() => {
      modalRef.current.classList.remove("show-modal");
    }, 9000);
  }

  return (
    <div className="blog">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Our media corner"
        middleHeader="Blog, cheatsheets and other internal material"
        messageHeader="Welcome. Please, verify your account using special URL and credentials sent to you by e-mail and continue to use everything you have rights assigned to."
      />

      <div className="blog-home-container">
        <div className="blog-home-container-box">
          <Link to="/blog" onClick={openModalWarn}>
            Why is your business a second tier business without presence on the
            Internet nowadays?
          </Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog" onClick={openModalWarn}>
            How the price of a project is determined?
          </Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog" onClick={openModalWarn}>
            Reasons why you should not leave your project to anyone.
          </Link>
        </div>
      </div>

      <div className="modal-warn" ref={modalRef}>
        <img
          src={require("../files/mathscript.jpg")}
          alt="Mathscript Ltd logo (Zagreb, Croatia, web-agency)"
        />{" "}
        <br />
        <br />
        Currently not visible publicly. It will be allowed in a just few weeks
        with a lot of other interesting articles. <br />
        <br />
        Follow us on social media or contact us{" "}
        <Link
          to="/contact"
          style={{
            color: "#d82020",
            backgroundColor: "white",
            borderRadius: "10px",
            paddingInline: "0.5rem",
            textDecoration: "none",
            letterSpacing: "4px",
          }}
        >
          here
        </Link>{" "}
        right now.{" "}
      </div>

      <div className="blog-restricted">
        <p>
          More content will not be shown if you have not granted access approved
          by Mathscript.
        </p>
      </div>

      <ImWarning className="warn-icon-blog" />

      <p className="blog-let-us-know">
        To access the internal content, you should have assigned rights with
        special link and credentials to do so. If you have it, but something
        does not work,{" "}
        <a href="mailto:mathscript@mathscript.hr">be free to let us know</a> and
        solve the issue immediately.
        <br />
        <br />
        <br />
        <br />
        <br />
        <i>
          <strong style={{ color: "#4eada2" }}>
            {" "}
            The plan is to open the blog in a month for wider use, with all
            articles that can be seen publicly, separated from our internal
            materials. A notice will follow soon after we reorganise the
            content.{" "}
          </strong>{" "}
        </i>
      </p>
      <GotProject />
      <Footer />
    </div>
  );
}

export default Blog;
