import React, { useEffect } from "react";
import BlogHome from "./BlogHome";
import CanvasDraw from "./CanvasDraw";
import CitiesAffiliate from "./CitiesAffiliate";
import ContactButton from "./ContactButton";
import Footer from "./Footer";
import GotProject from "./GotProject";
import "./Home.css";
import Introduction from "./Introduction";
import LastProjects from "./LastProjects";
import ListDescription from "./ListDescription";
import Navigation from "./Navigation";
import NewStandards from "./NewStandards";
import SomeBenefits from "./SomeBenefits";
import SpreadMenu from "./SpreadMenu";

function Home() {
  document.title = "Mathscript — shaping digital Earth";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="home">
      <Navigation />
      <SpreadMenu />
      <ContactButton />
      <CanvasDraw
        header="Shaping an idea to fit a digital Earth"
        middleHeader=""
        messageHeader="We have been enjoying building and creating websites, apps and software for years. Let's raise your business together."
        toShow="true"
      />
      <Introduction />
      <NewStandards />
      <ListDescription />
      <BlogHome />
      <SomeBenefits />
      <LastProjects />
      <CitiesAffiliate />
      <GotProject />
      <Footer />
    </div>
  );
}

export default Home;
