import React from "react";
import "./CareersDetails.css";
import { Link } from "react-router-dom";

function CareersDetails() {
  return (
    <div className="careers-details">
      <h3 className="link-controller blog-let-us-know form-h3">
        Let's begin with what we offer
      </h3>
      <div className="cd-offer">
        <div className="cd-offer-box">
          <img
            src={require("../files/flexwork.jpeg")}
            alt="Mathscript allows you to work remotely, even with laptop on the beach if you prefer it."
          />
          <h3>FLEXIBLE WORKING HOURS AND EVEN LOCATION</h3>
          <p>
            The formula is simple — if you do your part of the job on time, we
            do not bother where and when you are working.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/education.jpeg")}
            alt="A girl learning with books in her hands - payed education in Mathscript."
          />
          <h3>CONSTANT PAYED EDUCATION</h3>
          <p>
            IT is an area where progress is constant. So we make sure employees
            are updated. It helps employee, but also us.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/conferenceslondon.jpeg")}
            alt="Mathscript organises conferences, for example in London, but also in other part of European continent."
          />
          <h3>JOURNEYS TO CONFERENCES</h3>
          <p>
            From time to time, we organise journeys to various conferences,
            mostly in the western or central part of Europe. Their purpose is to
            encourage employee to reveal new technologies nad constantly working
            on improving his/her skills. Conferences can be also viral.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/plane.jpeg")}
            alt="A plane takes off from port. Travelling to take a break from the job."
          />
          <h3>NON-BUSINESS TRAVELS</h3>
          <p>
            Also, Mathscript may sometimes organise trips that are primarily
            oriented on relaxing and rest from work. Although almost in Europe,
            we assume that travelling to other part in the world could be also
            on the table in the near future, as we grow as a community.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/feedback.jpeg")}
            alt="In Mathscript you get feedback every week to improve your experience."
          />
          <h3>GIVING YOU FEEDBACK</h3>
          <p>
            We do not neglect workers, but help them, approach them regularly
            and give feedback if we think it can help them and encourage them to
            better work habits.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/bulb.jpeg")}
            alt="Bulb as a symbol of ideas and proactiveness."
          />
          <h3>ENCOURAGING PROACTIVENESS</h3>
          <p>
            We appreciate employees who come up with new and positive ideas that
            encourage the progress of everyone in the company. We always take
            suggestions into consideration.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/football.jpeg")}
            alt="Girls playing football in the dawn of FIFA Football World Cup in Qatar 2022 (Mathscript, Zagreb, Savska cesta 41)"
          />
          <h3>TABLE SOCCER AND MORE</h3>
          <p>
            Everyone can take a break from working. For this reason, we have a
            few surprises how to do that. One of them are table soccer, enjoying
            in the kitchen or watching TV.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/daysoff.jpeg")}
            alt="Mathscript gives more days off than law minimum is set."
          />
          <h3>ADDITIONAL DAYS OFF</h3>
          <p>
            We know what the labour laws provide, but we always try to worry
            more, so we give more days off than the regulations.
          </p>
        </div>

        <div className="cd-offer-box">
          <img src={require("../files/roadout.jpeg")} alt="" />
          <h3>CAREER SWITCH</h3>
          <p>
            Although we use MERN when working on projects, we are open to make a
            deal and accept other technologies, even pay education for it, if we
            can cooperate successfully on that way.
          </p>
        </div>
      </div>
      <h3 className="link-controller blog-let-us-know form-h3">
        Your expected qualities
      </h3>
      <div className="cd-you">
        <img
          src={require("../files/redclouds.jpeg")}
          alt="Red clouds - Mathscript (Zagreb), web-development agency"
          draggable="false"
        />

        <div className="cd-you-boxes">
          <div className="cd-you-box">
            <h3>ENJOYING YOUR WORK</h3>
            <p>
              The first condition absolutely. If you do not enjoy the work as
              one of the biggest parts of your life, think about your goals
              twice.
            </p>
          </div>
          <div className="cd-you-box">
            <h3>INTELLIGENT AND LEARNING-READY PERSON</h3>
            <p>
              Every person working in this field should be intelligent, brave
              and always create new ideas and solutions. You have face with that
              by now.
            </p>
          </div>
          <div className="cd-you-box">
            <h3>NO KNEELING BEFORE THE PROBLEM</h3>
            <p>
              In our jobs, we face with problems every day. But we know one
              thing - every problem has counterattack and it is called solution.
            </p>
          </div>
          <div className="cd-you-box">
            <h3>YOU KNOW YOUR JOB</h3>
            <p>
              None of us are perfect, but we can work on our own, which does not
              mean even the biggest players do not sometimes ask for advice.
            </p>
          </div>

          <div className="cd-you-box">
            <h3>SUPPORTING OTHERS</h3>
            <p>
              As you may need help occasionally, it would be nice to help others
              also. Good things come to those who are good :).
            </p>
          </div>
        </div>
      </div>
      <h3 className="link-controller blog-let-us-know form-h3">
        Have you some of these prerequisites?
      </h3>
      <div className="cd-conditions">
        <div className="cd-conditions-box">
          <img
            src={require("../files/reactcareer.png")}
            alt="React JS programmer - Mathscript (Zagreb)"
          />
          <h3>REACT JS PROGRAMMER</h3>
          <ul>
            <li>two years of experience</li>
            <li>excellent knowledge of JS</li>
            <li>preferable using functional components</li>
            <li>independent use of hooks</li>
            <li>at least few examples of projects</li>
            <li>obligatory to know CSS handling</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/nodecareer.jpeg")}
            alt="Node JS programmer - Mathscript (Zagreb)"
          />
          <h3>NODE JS PROGRAMMER</h3>
          <ul>
            <li>two years of experience</li>
            <li>excellent knowledge of Express JS</li>
            <li>perfect understanding of databases</li>
            <li>working with MongoDB</li>
            <li>cooperation with frontend programmers</li>
            <li>advanced knowledgde of JS</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/figmacareer.png")}
            alt="Figma UX/UI designer - Mathscript (Zagreb)"
          />
          <h3>DESIGNER (UX/UI)</h3>
          <ul>
            <li>two years of experience</li>
            <li>advanced knowledge of CSS and HTML</li>
            <li>using some tool such as Figma</li>
            <li>ability to create complex works</li>
            <li>at least few examples of projects</li>
            <li>communicating with clients</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/reactcareer.png")}
            alt="React Native programmer - Mathscript (Zagreb)"
          />
          <h3>REACT NATIVE PROGRAMMER</h3>
          <ul>
            <li>two years of experience</li>
            <li>excellent knowledge of JS</li>
            <li>knowing functional components of React</li>
            <li>knowing hooks</li>
            <li>at least few examples of projects</li>
            <li>previous work with Android or iOS development is plus</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/favicon.png")}
            alt="React JS programmer - Mathscript (Zagreb)"
            className="logo-career"
            draggable="false"
          />
          <h3>SOME OTHER SKILLS</h3>
          <p>
            If you have some other similar skill or skill you think could be
            relevant for us, do not wait and check it. Send us your portfolio,
            CV, knowledge description and we will certainly asnwer you, either
            with positive answer or save your information to our list. Of
            course, we will surely call you after if you will have will to
            cooperate.
          </p>
        </div>
      </div>

      <h3 className="link-controller blog-let-us-know form-h3">
        Currently open positions
      </h3>
      <h4>
        There are currently no open working positions. When they will be, they
        will be listed here with a detailed description of the necessary
        conditions and benefits. Moreover, they will be firstly announced
        through social networks marketing campaigns and other means of
        communication to reach as much high as possible number of people.
      </h4>

      <h3 className="link-controller blog-let-us-know form-h3">
        Still thinking you have skills for us?{" "}
      </h3>
      <p className="open-job-text">
        You can apply openly for a job at any time if you think you have the
        skills to fit into everything you read above. Feel free to click below
        and fill out the standard contact form and we promise to respond even if
        we do not need an extension right now. If you want, we will always be
        able to call you later.
      </p>
      <Link className="open-job-link" to="/contact">
        FILL OUT THE CONTACT FORM
      </Link>
    </div>
  );
}

export default CareersDetails;
