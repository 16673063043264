import React, { useEffect } from "react";
import "./ServicesDetails.css";
import { Link } from "react-router-dom";

import website from "../files/website-a.jpeg";
import webapp from "../files/webapp-a.jpeg";
import webshop from "../files/webshop-a.jpeg";
import design from "../files/design-a.jpeg";
import contentcr from "../files/content-create-a.jpeg";
import cms from "../files/cms-a.jpeg";
import seo from "../files/seo-a.jpeg";
import hosting from "../files/hosting-a.jpeg";
import socialmed from "../files/social-media-a.jpeg";
import sm from "../files/sm-a.jpeg";
import appdevelopment from "../files/mobile-app.jpeg";

import btc from "../files/bitcoin-logo.png";
import highFive from "../files/high-five.jpeg";
import volunteer from "../files/volunteer.jpeg";

function ServicesDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="sd">
      <h1 className="link-controller blog-let-us-know form-h3">
        Let's take a look how to improve your company or organisation
      </h1>
      <div className="services-div">
        <div className="services-div-box">
          <img
            src={website}
            alt="Doing website creation projects - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">WEBSITES</p>
            <p className="second-p">
              Every year there are 10-15 % more websites in the world. Their
              number is about two billion today. Not being present on the
              Internet today means having a lower reputation in the eyes of
              clients. It means being visible up to 10 times less. Modern
              companies cannot afford so many losses. Change that and start
              growing your company.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">WEB-APPS</p>
            <p className="second-p">
              Whether you run an apartment, a travel agency, a restaurant, a
              coffee shop, a non-profit organisation, a party, a bank, another
              financial institution, you want to expand sales around the world -
              let technology speed up your business so you can dedicate yourself
              to revenue growth. Or maybe you just need your professional blog
              or something similar. Change your business for the better and
              faster now.
            </p>
          </div>
          <img
            src={webapp}
            alt="Web apps (or web-applications) creation - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={webshop}
            alt="Webshop projects - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">WEBSHOPS</p>
            <p className="second-p">
              The times of hand-to-hand trading are behind us. Much of today’s
              trade takes place online and the percentage is growing drastically
              every year. By having an online store, you expand the range of
              your customers several times and open new doors to the digital
              world. Do not let time trample you and the competition to take
              more of your clients and money.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">UX/UI DESIGN</p>
            <p className="second-p">
              We love colours, design and that things fit together perfectly. We
              also love to turn colourful client ideas into reality. People get
              an impression of your work in the first three seconds of a visit.
              Let's then work out to make that impression the way you want it.
              And your business wants.
            </p>
          </div>
          <img
            src={design}
            alt="UX and UI design, colourful sample represented - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={contentcr}
            alt="Content creation instead of costumer, only for website, web apps, webshop or mobile development projects - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">CONTENT CREATION</p>
            <p className="second-p">
              We know that times are fast-paced today and you may not have time
              to come up with text or look for pictures for your page. Don't
              worry, at your request we can do it for you. Even though you know
              your job best, we will do our best to replace you and get the most
              out for your company or organisation.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">SELF-MANAGEMENT IN APP (CMS)</p>
            <p className="second-p">
              Would you change the content on the page often? Do you like to
              change everything regularly or does your job simply make you do
              it, and constantly paying for minor changes eventually becomes
              expensive? No problem, the site may have the ability to edit
              content that is not up to us. The price may be higher in the
              beginning, but in the long run you have peace, flexibility and
              independence, and in the end you even save money through months.
              Check with us.
            </p>
          </div>
          <img
            src={cms}
            alt="CMS - content management system - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={seo}
            alt="SEO - search engine optimisation for Google search results and better position there - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">SEO-OPTIMISATION</p>
            <p className="second-p">
              Some pages are easier to reach on Google, some harder. This can be
              improved by performing the so-called SEO. Don't worry, we
              incorporate the most basic SEO into every project to make your
              project recognisable, but if you want, we can do "wider" and
              advanced optimisation to make your site even more likely to stand
              out among the first results.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">DOMAIN AND HOSTING MAINTENANCE</p>
            <p className="second-p">
              Once we formally complete your project, we put it online. For this
              to be possible, you need to have a leased and maintained domain,
              as well as storage space for the site. Mathscript is there to do
              it all without you even having to think about what is going on in
              the background. Better do your job, and leave us to do ours.
            </p>
          </div>
          <img
            src={hosting}
            alt="Mathscript web agency solves the problem of hosting for your website or web project and domain buying, like .com or .eu or .net or .org or any other available"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={socialmed}
            alt="Photography device in front of the sign representing social network Facebook - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">SMART ADVERTISING AND MARKETING</p>
            <p className="second-p">
              Your site is already online and you look more serious, but you
              would like to go a step further and to people hear even more about
              you. We do smart marketing, making sure to run an ad about your
              organisation only to an audience that would like to hear about it,
              without wasting money excessively because the ad is poorly placed.
              Don't worry, we will explain everything to you before you make a
              decision.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">MAINTENANCE OF SOCIAL NETWORKS</p>
            <p className="second-p">
              Would you like to have profiles on several social networks to
              bring your work closer to your target people, and you do not have
              time to deal with that? That is totally fine - this is a time
              consuming job, and it doesn't allow you to dedicate yourself to
              your original work. We will easily agree to take it upon ourselves
              at the pace as we agree.
            </p>
          </div>
          <img
            src={sm}
            alt="Social media content creation and management - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={appdevelopment}
            alt="Mobile phone with open mobile app made for iOS device - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">MOBILE APPS (BOTH ANDROID AND iOS)</p>
            <p className="second-p">
              Sugar always comes last. You may have heard that in the West, the
              vast majority of Internet traffic takes place on mobile phones. If
              you haven’t, the reason more is to opt not only for a website that
              will look perfect on mobile phones, but also for an app. No need
              to say, but you can guess for yourself how much your business
              outperforms the competition if you own such a solution. Don't wait
              - stand out.
            </p>
          </div>
        </div>
      </div>
      <h1 className="link-controller blog-let-us-know form-h3">
        Let’s summarise quickly what makes us different
      </h1>
      <p className="sd-different">
        It is not a shame to be different. Different ones wrote history, drove
        progress, created a better world. Let's round up once again the things
        we want you to know and we encourage you to show your uniqueness from
        the competition right now. Let's get in touch.
      </p>
      <ul className="sd-list">
        <li>
          We solve a whole range of services in the field of IT, but also
          marketing, design and content creation.
        </li>
        <li>
          We respect the needs of the client and we adapt to him, having the
          understanding to find the best value for money.
        </li>
        <li>
          Our work does not end with the development of the service, but we
          still make sure that everything in the background works perfectly, so
          that the client always addresses us with confidence.
        </li>
        <li>
          We are available to clients every day, including weekends. Any
          interference must not interfere with anyone's business any day of the
          year.
        </li>
        <li>
          Users rated us with a score of almost 5.0. If you are interested in
          why, feel free to check it out and let’s start a collaboration.
        </li>
        <li>
          Usually, we finish your project much faster than most of programmers,
          due to our experience. Check and use that.
        </li>
        <li>
          Yes, it is true, we accept payment in a number of known
          cryptocurrencies. Use them wisely and invest in your business.
        </li>
        <li>
          Mathscript pays you into your account if you bring your friend as a
          client. A certain percentage of the value of the project goes to you.
          Get to work right away.
        </li>
        <li>
          There are so many more amenities and special features, but we won't
          bother you anymore. We are still waiting for you to present your
          project to us and we will finally start with everything.
        </li>
      </ul>
      <h1 className="link-controller blog-let-us-know form-h3">
        Want to know how do we do your project?
      </h1>
      <h4>
        <Link to="/about">
          No problem. We use so-called MERN-technology. Maybe that means nothing
          to you or sounds like some nuclear physics. In that case, go ahead and
          skip that. But if you are the one who wants to know details in
          background, click and check. You can ask us additionally if we were
          not clear enough. It is alright, we are all just humans and it can
          happen we are not on the same wave length.
        </Link>
      </h4>

      <h1 className="link-controller blog-let-us-know form-h3">
        With our service you can get more
      </h1>

      <br />
      <br />
      <br />

      <p className="sd-benefits-info">
        1 PAYING WITH CRYPTO ACCEPTABLE <br />
        <span>
          If you decide to pay with crypto, you should announce that before
          making a receipt. If you do so, than you are obliged to pay all the
          value of the receipt in one installment. We will recalculate the value
          in EUR in one of appropriate cryptos according to the exchange rate on
          that day. Than we will send you bill and after receiving information
          via e-mail, you will have to pay inside the time period of 15 minutes.
          After confirmation that everything was right, mostly the same day, we
          send message to you with announcement of project making start. Also,
          we will turn you money back if something goes wrong and fault is on
          Mathscript's side. In every other case there is no possibility of
          returning money. For supported cryptocurrencies, please, contact us
          (beacuse it can vary from time to time).
        </span>{" "}
      </p>
      <p className="sd-benefits-info">
        2 WE PAY YOUR MONEY IF YOU FIND US A CLIENT <br />
        <span>
          The thing is very simple. Contact us with a message in which you
          recommend us a user and provide your personal information (exact name,
          surname, address and personal number). If the recommended user accepts
          the service, it is successfully realised and the full amount of the
          invoice is paid, a certain percentage of that value is paid to your
          account. We will ask for your account information in a timely manner.
          The percentage is 5 or 8 %, depending on the value of the project
          (more or less than 1000 EUR). You can use the benefit more than once,
          which means that you will get money for every next new client/person.
        </span>{" "}
      </p>
      <p className="sd-benefits-info">
        3 DISCOUNT FOR NGOs AND YOUNGER COMPANIES <br />
        <span>
          Things are here also straitforward. If the bill will be on non-profit
          organisation, such as association, political party, humanitarian
          organisation etc., we will bill you and than give you discount of 10 %
          on total value. The same goes if your profitable company is
          registrated formally before three months or less. In every of this
          case, a bill should be named on that subject and responsible person,
          i. e. president or secretary, must send us legal document which
          confirms the status that is the reason of getting discount. Documents
          that cannot be checked for authencity will be rejected to prevent
          fraud. One subject can use this benefit only once - just for the first
          bill, regardless on next bill value.
        </span>
      </p>
      <p className="sd-benefits-info">
        4 INSTALLMENT PAYMENTS <br />
        <span>
          It is negotiated with each individual client and depends on a
          case-by-case basis. First of all, about the value of the project and
          then the length of the development, as well as preferences and
          creditworthiness of users. Not applicable for cryptocurrency payments.
        </span>
      </p>

      {/* <SomeBenefits /> */}

      <div className="some-benefits">
        <div className="some-benefits-box">
          <h1>PAY WITH CRYPTO</h1>
          <h3>1 MIN</h3>
          <span>AFTER YOU RECEIVE MAIL</span>
          <img
            src={btc}
            alt="Bitcoin and other cryptocurrencies are acceptable way of paying in Croatian IT company Mathscript, only one in Croatia and Zagreb"
            draggable="false"
          />
          <p>
            It is possible to pay in about ten cryptocurrencies. Then it is
            necessary to pay the entire amount at once and within 15 minutes of
            receiving instructions as agreed.
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>BRING US A CLIENT AND GET MONEY</h1>
          <h3>8 %</h3>
          <span>OF PROJECT VALUE GOES TO YOUR WALLET</span>
          <img
            src={highFive}
            alt="Mathscript has action which can give 10 % of project value to person who brings us a new client or user"
            draggable="false"
          />
          <p>
            If you recommend your friend, he takes the service and we
            successfully complete the project, after payment we pay you 8 % of
            the value on your account, or 5 % if the value is above 1000 EUR
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>NGO OR COMPANY YOUNGER THAN 3 MONTHS?</h1>
          <h3>— 10 %</h3>
          <span>DISCOUNT ON THE TOTAL PRICE</span>
          <img
            src={volunteer}
            alt="Non-governmental organisations, political parties, associations and companies (firms) that started to work (were registered officially) in a period less than three months ago - get 10 % of discount"
            draggable="false"
          />
          <p>
            Non-profit organisations, political parties, associations and
            companies that started to work — meaning they were registered
            officially in a period less than three months ago - get 10 % of
            discount
          </p>
        </div>
      </div>

      {/* <SomeBenefits /> */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="coffee">
        <img
          src={require("../files/coffee.png")}
          alt="Cup of coffee - the beginning of every busy day in Mathscript"
          draggable="false"
        />
      </div>
      <h1 className="bright-text">
        Several cups of coffee and your project is ready to be shown.
      </h1>
    </div>
  );
}

export default ServicesDetails;
