import React from "react";
import "./Footer.css";

import { MdDashboard } from "react-icons/md";
import { MdViewQuilt } from "react-icons/md";
import { RiBubbleChartFill } from "react-icons/ri";

import { MdMarkEmailRead } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { BsFacebook } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { GrLinkedin } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { FaViber } from "react-icons/fa";

import btc from "../files/bitcoin-logo.png";
import rainy from "../files/rainy.jpeg";
import eu from "../files/eu.jpeg";
import mathscript from "../files/favicon.png";
import { Link } from "react-router-dom";

function Footer() {
  const date = new Date();
  const day = date.getDate().toString();
  const year = date.getFullYear().toString();
  const month = date.getMonth() + 1;

  var monthName;

  switch (month) {
    case 1:
      monthName = "January";
      break;
    case 2:
      monthName = "February";
      break;
    case 3:
      monthName = "March";
      break;
    case 4:
      monthName = "April";
      break;
    case 5:
      monthName = "May";
      break;
    case 6:
      monthName = "June";
      break;
    case 7:
      monthName = "July";
      break;
    case 8:
      monthName = "August";
      break;
    case 9:
      monthName = "September";
      break;
    case 10:
      monthName = "October";
      break;
    case 11:
      monthName = "November";
      break;
    case 12:
      monthName = "December";
      break;
    default:
      monthName = "NO-DETECT";
  }

  return (
    <div className="set100">
      <div className="footer-main">
        <div className="footer-rect rect-one">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/services">Services</Link>
          <Link to="/work">Work</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/careers">Careers</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/privacy-policy">Privacy policy</Link>
          <Link to="/error">Error - 404</Link>

          <p className="btc-text">
            Crypto acceptable as a mean of payment (not just bitcoin, but more){" "}
          </p>

          <img
            src={btc}
            alt="Paying with crypto possible in Mathscript Ltd, only IT-company in Zagreb, Croatia and the European Union accepting that in this currency"
            className="btc-img"
            draggable="false"
          />

          <p className="btc-text">
            If you recommend us someone as a client, you can get a certain
            percentage of the value of the project paid
          </p>

          <img
            src={rainy}
            alt="If you recommend to Mathscript someone as a client, you can get a certain
            percentage of the value of the project paid"
            className="btc-img"
            draggable="false"
          />
        </div>

        <div className="footer-rect rect-two">
          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Naziv: </span>Mathscript jednostavno društvo s ograničenom
              odgovornošću za računalno programiranje
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Skraćeni naziv: </span>Mathscript j. d. o. o.
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Sjedište: </span>Savska cesta 41, 10 000 Zagreb
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>OIB: </span>47470088561
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Nadležni sud (sudski registar): </span>Trgovački sud u
              Zagrebu
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Broj up. u reg. (MBS): </span>081403853
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Iznos temeljnog kapitala: </span>10 HRK
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Temeljni kapital uplaćen u cijelosti: </span>da
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Banka: </span>Raiffeisenbank Austria d. d., Magazinska cesta
              69, 10 000 Zagreb
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>SWIFT: </span>RZBHHR2X
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>IBAN: </span>HR5324840081135232784
            </p>
          </a>

          <img
            src={mathscript}
            alt="Paying with crypto possible in Mathscript Ltd, only IT-company in Zagreb, Croatia and the European Union accepting that in this currency"
            className="btc-img logo-footer"
            draggable="false"
          />
          <p className="logo-footer-text">
            Mathscript, limited liability company for computer programming based
            in Zagreb, the Republic of Croatia, the European Union
          </p>
        </div>

        <div className="footer-rect rect-three">
          <a
            href="mailto:mathscript@mathscript.hr"
            target="_blank"
            rel="noreferrer"
          >
            <p>mathscript@mathscript.eu</p> <MdMarkEmailRead />
          </a>
          <a
            href="mailto:support@mathscript.hr"
            target="_blank"
            rel="noreferrer"
          >
            <p>support@mathscript.eu</p> <HiOutlineMail />
          </a>
          <a
            href="https://www.facebook.com/MathscriptEU/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Facebook</p> <BsFacebook />
          </a>
          <a
            href="https://www.instagram.com/mathscript.eu/?hl=en"
            target="_blank"
            rel="noopener"
          >
            <p>Instagram</p> <GrInstagram />
          </a>
          <a
            href="https://www.linkedin.com/in/mathscript-web-development-agency-106930232/"
            target="_blank"
            rel="noreferrer"
          >
            <p>LinkedIn</p> <GrLinkedin />
          </a>
          <a
            href="https://twitter.com/MathscriptEU"
            target="_blank"
            rel="noreferrer"
          >
            <p>Twitter</p> <BsTwitter />
          </a>
          <a
            href="viber://chat?number=385977656906"
            target="_blank"
            rel="noreferrer"
          >
            <p>Viber (message)</p> <FaViber />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=385977656906"
            target="_blank"
            rel="noreferrer"
          >
            <p>Whatsapp (message)</p> <BsWhatsapp />
          </a>

          <img
            src={eu}
            alt="Flag of the European Union, which has funded Mathscript as a start-up through its social and economic funds from the EU budget."
            draggable="false"
          />
          <h3>
            Funded by the European Union funds (web created by Mathscript)
          </h3>
        </div>
      </div>

      <div className="cr">
        <p>Mathscript - all rights reserved</p>
        <div>
          <MdDashboard className="icon-red" />
          <MdViewQuilt className="icon-red" />{" "}
          <RiBubbleChartFill className="icon-red" />{" "}
        </div>
        <p>{day + " " + " " + monthName + " " + year + " "}</p>
      </div>
    </div>
  );
}

export default Footer;
