import React, { useEffect } from "react";
import "./PrivacyPolicy.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import PrivacyPolicyText from "../components/PrivacyPolicyText";

function PrivacyPolicy() {
  document.title = "Privacy policy — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="privacy-policy">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="We respect your data"
        middleHeader="Here is how we protect them"
        messageHeader="All basic information about handling clients data are given on this link, but — nevertheless, you can find out more if contact us. Start reading below."
      />

      <PrivacyPolicyText />

      <GotProject />
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
