import React from "react";
import "./AboutDetails.css";

import headquarters from "../files/headquarters.jpeg";

import react from "../files/reactjs.png";
import node from "../files/nodejs.jpeg";
import express from "../files/expressjs.jpeg";
import mongo from "../files/mongodb.png";
import reactnative from "../files/react-native.png";
import javascript from "../files/js.png";
import scss from "../files/sass.jpeg";
import css from "../files/html-css.png";
import figma from "../files/figma.png";
import ads from "../files/fb-ads.png";
import tensorflow from "../files/tf.png";

import CitiesAffialiate from "../components/CitiesAffiliate";
import ContactMap from "../components/ContactMap";

function AboutDetails() {
  return (
    <div className="about-details">
      <h1 className="link-controller blog-let-us-know form-h3">
        Our goals and how you can profit from them
      </h1>
      <ul className="goals">
        <li>
          Help our associates move their business in line with the new times
        </li>
        <li>
          Provide equal assistance to small and large organisations, without
          neglecting their specifics as is generally the case
        </li>
        <li>
          Do not fix the price in a way that digital services are inaccessible
          to smaller entities, but adjust it to the client's budget so that they
          are available to everyone
        </li>
        <li>
          Always be guided by the optimal ratio of price and quality, without
          basing the business on the exploitation of customers
        </li>
        <li>
          Be available to the user every day, so that his service would never be
          in bad shape
        </li>
        <li>
          Strictly respect the deadlines given to the client, even do his
          project before the promised deadline as a sign of attention, in which
          we are mostly successful and which users positively evaluate
        </li>
        <li>
          Reward financially those who are not our clients, but have helped us
          get a client
        </li>
        <li>
          Constantly accepting modernisation for the benefit of clients, such as
          accepting cryptocurrencies, but also a number of other things
        </li>
        <li>
          To do every client a service by writing code just for him in excellent
          MERN-technology, without the use of terribly slow, unprofessional and
          bad templates that would harm their seriousness
        </li>
        <li>... and much, much more, but you got the point already...</li>
      </ul>
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <h1 className="link-controller blog-let-us-know form-h3">
        Choice is easier than ever
      </h1>
      <div className="ad-last-why">
        <div className="ad-last-why-box">
          <h1>WRITING CUSTOM CODE, NO LOW-QUALITY TEMPLATES</h1>
          <p>
            Many agencies resell low-quality templates that damage the
            seriousness and reputation of your business. In addition to being
            recognisable, templates are significantly slower, give less ability
            to customise content and indicate the frivolity of your business. A
            car repair can also be done by your neighbour, but that will come
            with huge cost.
          </p>
        </div>
        <div className="ad-last-why-box">
          <h1>WE ARE ONLY ONE THAT GIVES YOU MONEY IF YOU FIND US A CLIENT</h1>
          <p>
            We have several benefits and if you even don't need our service at
            all, you can still profit. If you recommend us to a friend or anyone
            else, you can get up to 8 % of the project value on your account.
            That is what Mathscript pays you for. If you find more, it is the
            same story every time. See further on the page other great benefits
            also.
          </p>
        </div>
        <div className="ad-last-why-box">
          {" "}
          <h1>
            YOU HAVE SOME CRYPTO AND DON'T KNOW WHERE TO SPEND THEM? YOU ARE IN
            THE RIGHT PLACE
          </h1>
          <p>
            In today's times that are economically and politically unstable, we
            have decided to continue accepting payments in a number of
            cryptocurrencies, at least 10. If you have enough for one-time
            payment, you can pay for any of our services, whatever the price.
            The process is ridiculously simple.
          </p>
        </div>
        <div className="ad-last-why-box">
          <h1>
            WE ARE AVAILABLE EVERY DAY, WE RESPOND QUICKLY AND WE MAKE THE
            PROJECT FASTER THAN THE USUAL DEADLINE
          </h1>
          <p>
            In fact, everything is described in the title. When you contact us,
            we respond quickly, you don't have to worry if currently is weekend.
            Also, our clients admitted to us that we completed the project
            earlier than the assessments of other competitors with whom they
            communicated. The reason is simple - we are committed to work and
            clients and have huge experience in the field we love.
          </p>
        </div>
      </div>
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <h1 className="link-controller blog-let-us-know form-h3">
        This is what we use to make your project outstanding
      </h1>
      <div className="services-div">
        <div className="services-div-box">
          <img
            src={react}
            alt="React Javascript - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">REACT JS</p>
            <p className="second-p">
              React is the most used framework (more precisely library) of the
              JS programming language. React creates applications that feel as
              if they are using a native mobile service, which users today
              prefer and represents a sought user experience. The code is
              divided into components which makes it much more reusable.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">NODE JS</p>
            <p className="second-p">
              Node is the technology we use to backend, everything that happens
              in the background of the application, such as connecting to
              databases and functionalities that are not visible through the
              external design of the application.
            </p>
          </div>
          <img
            src={node}
            alt="Node Javascript - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={express}
            alt="Express Javascript - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">EXPRESS JS</p>
            <p className="second-p">
              It is a Node JS library that serves to make it easier to use Node
              JS, which is more complicated itself and requires more time and
              code. Express allows the project to have all the necessary backend
              functionality and provide a perfect impression to your clients
              when visiting your site.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">MONGO DB</p>
            <p className="second-p">
              Mongo is one of the five most used databases among developers. The
              use is extremely intuitive and fits perfectly with other
              technologies we use (MERN - Mongo, Express, React, Node). It helps
              us store information about your business and users that must be
              0-24 present and able to call them.
            </p>
          </div>
          <img
            src={mongo}
            alt="Mongo Database as a part of MERN - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={javascript}
            alt="Javascript or JS — programming language, one of the most used, primarily for web-development - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">JAVASCRIPT</p>
            <p className="second-p">
              You don’t need to use a lot of words. JS is the best known, most
              used and one of the most desirable programming languages. Its
              plain version, Vanilla JS, opens in combination with other
              technologies and libraries (MERN) the possibility of making
              practically anything, from a frontend to a backend, an artificial
              intelligence systems and similar.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">REACT NATIVE</p>
            <p className="second-p">
              React Native is a kind of version of React for mobile
              applications. It is possible to create native applications that
              "convert" to both versions, Android and iOS, in the background
              process.
            </p>
          </div>
          <img
            src={reactnative}
            alt="React Native for mobile development, both Android and iOS - Mathscript web-agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={scss}
            alt="SCSS or SASS, advanced or SuperCSS, sometimes called CSS with superpowers. Allows app styling with some coding options similar to using a real programming language."
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">SCSS (SASS)</p>
            <p className="second-p">
              SCSS or SASS, advanced or SuperCSS, sometimes called CSS with
              superpowers. Allows app styling with some coding options similar
              to using a real programming language. Basically, in the core, it
              is regular CSS and the end result is the same.{" "}
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">CSS AND HTML</p>
            <p className="second-p">
              An essential part of any web-application development package. HTML
              is used to create the basic skeleton of the page, the elements
              themselves such as links, text, images and similar, while CSS
              gives styles to all of them, giving them positions, fonts,
              colours, shapes and much more.
            </p>
          </div>
          <img
            src={css}
            alt="HTML - Hypertext Markup Language and CSS - Cascade Stylesheet - Mathscript web-agency"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={figma}
            alt="Figma designing tool for UX/UI design and frontend-development"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">FIGMA</p>
            <p className="second-p">
              Figma is a tool we use for UX and UI-design. UX represents the
              user experience, while UI the user interface. The goal is to
              create an application not only pleasing to the eye, but also such
              that the user uses it with ease and without objections, which has
              a positive effect on your business, too.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">FACEBOOK AND GOOGLE ADS</p>
            <p className="second-p">
              In order for your project to be completely successful, it is
              necessary to introduce it to as many people as possible after its
              creation. Only then will your business grow and bring you income.
              Mathscript therefore performs advertising on the so-called
              "smarter way", so that the ad reaches the desired audience,
              without wasting the money on an audience that is not interested in
              it at all.
            </p>
          </div>
          <img
            src={ads}
            alt="Facebook logo for advertisement, without Google colourful logo"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={tensorflow}
            alt="Tensorflow Javascript for artificial intelligence projects and systems - Mathscript web and mobile-development agency in Zagreb, Croatia, the European Union"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">TENSORFLOW JS</p>
            <p className="second-p">
              Tensorflow JS allows you to use the benefits of artificial
              intelligence using the Javascript programming language, instead of
              Python or someone more common for AI. Since Mathscript only covers
              certain segments of the AI area, please contact us for these
              services first to see if we can help you in this area.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <h1 className="link-controller blog-let-us-know form-h3">
        Information about Mathscript
      </h1>
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <div className="about-info-eng">
        <img
          src={require("../files/favicon.png")}
          alt="Paying with crypto possible in Mathscript Ltd, only IT-company in Zagreb, Croatia and the European Union accepting that in this currency"
          draggable="false"
        />
        <a href="javascript:void(0)" className="first-link">
          <p>
            <span>Name: </span>Mathscript limited liability company for computer
            programming
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Short name: </span>Mathscript Ltd
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Headquarters: </span>Savska cesta 41, 10 000 Zagreb, the
            Republic of Croatia, the European Union
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Personal identification number: </span>47470088561
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Competent court (court register): </span>Trade Court in Zagreb
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Number in the register (MBS): </span>081403853
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>
              Share capital (according to Companies Law of the Republic of
              Croatia):{" "}
            </span>
            10 HRK
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Is share capital fully payed? </span>Yes
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Does the company operate in accordance with the laws? </span>
            Yes. Regular submission of annual financial reports to the tax
            administration.
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>
              CEO (<i>Croatian</i>: član uprave, direktor):{" "}
            </span>
            Tomislav Pilkić
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Bank: </span>Raiffeisenbank Austria d. d., Magazinska cesta
            69, 10 000 Zagreb, the Republic of Croatia, the European Union
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>SWIFT (for international payments): </span>RZBHHR2X
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>IBAN (International Bank Account Number): </span>
            HR5324840081135232784
          </p>
        </a>
      </div>
      {/*       ******************
       */}{" "}
      {/* ****************** */}
      {/* ****************** */}
      {/* ****************** */}
      {/* ****************** */}
      <h1 className="link-controller blog-let-us-know form-h3">
        Official information in Croatian (Trade court in Zagreb)
      </h1>
      <div className="about-info-cro">
        <a href="javascript:void(0)" className="first-link">
          <p>
            <span>Naziv: </span>Mathscript jednostavno društvo s ograničenom
            odgovornošću za računalno programiranje
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Skraćeni naziv: </span>Mathscript j. d. o. o.
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Sjedište: </span>Savska cesta 41, 10 000 Zagreb
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>OIB: </span>47470088561
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Nadležni sud (sudski registar): </span>Trgovački sud u Zagrebu
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Broj up. u reg. (MBS): </span>081403853
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Iznos temeljnog kapitala: </span>10 HRK
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Temeljni kapital uplaćen u cijelosti: </span>da
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Banka: </span>Raiffeisenbank Austria d. d., Magazinska cesta
            69, 10 000 Zagreb
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>SWIFT: </span>RZBHHR2X
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>IBAN: </span>HR5324840081135232784
          </p>
        </a>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="remove-padding">
        <CitiesAffialiate />
        <ContactMap />
        <img
          src={headquarters}
          alt="Headquarters of Mathscript Ltd or Mathscript j. d. o. o. in Zagreb, Savska cesta 41"
          draggable="false"
          className="headquarters-img"
        />
      </div>
    </div>
  );
}

export default AboutDetails;
