import React from "react";
import "./LastProjects.css";
import ScrollContainer from "react-indiana-drag-scroll";

import projectDJB from "../files/project-dostajebilo.png";
import projectMaja from "../files/project-fm.png";
import projectmmp7 from "../files/project-mmp7.png";
import projectLucich from "../files/project-luchich.png";
import projectEmmHouse from "../files/project-emmhouse.png";
import projectNikolich from "../files/project-nikolich.png";
import projectSolin from "../files/project-solin.png";
import projectRenata from "../files/projectrenata.png";

function LastProjects() {
  return (
    <div className="last-projects">
      <h1>Some of selected clients in 2022.</h1>
      <p>
        As a Mathscript, we have already cooperated with many other companies
        and civil associations and we will continue doing it further. Our
        employees also have comprehensive working experience during previous
        employments and education. Here are a few of chosen clients from this
        year.
      </p>
      <ScrollContainer className="project-logos">
        <img
          src={projectMaja}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
        />
        <img
          src={projectEmmHouse}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
        />
        <img
          src={projectLucich}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
        />
        <img
          src={projectSolin}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
        />
        <img
          src={projectDJB}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
          id="first"
        />

        <img
          src={projectNikolich}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
        />
        <img
          src={projectmmp7}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
        />
        <img
          src={projectRenata}
          alt="Example of projects made by Mathscript, blurred logo"
          draggable="false"
        />
      </ScrollContainer>
    </div>
  );
}

export default LastProjects;
