import React, { useEffect } from "react";
import "./About.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import AboutDetails from "../components/AboutDetails";

function About() {
  document.title = "About us — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="about">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Get to know us better"
        middleHeader=""
        messageHeader="Want to know more about us? No problem, here are the information you maybe wanted. If you will have additional questions, we would be happy to answer them any time. Let's start with the first step before moving on to help you with your project."
      />

      <AboutDetails />

      <GotProject />
      <Footer />
    </div>
  );
}

export default About;
