import React, { useEffect } from "react";
import "./Contact.css";

import Navigation from "../components/Navigation";
import CanvasDraw from "../components/CanvasDraw";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import MathscriptForm from "../components/MathscriptForm";
import ContactMoreData from "../components/ContactMoreData";
import ContactMap from "../components/ContactMap";

import headquarters from "../files/headquarters.jpeg";

function Contact() {
  document.title = "Contact us — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="contact">
      <Navigation />
      <SpreadMenu />
      <CanvasDraw
        header="Got a question?"
        middleHeader="We are ready to hear you."
        messageHeader="Let us know what kind of idea or project, or maybe doubt you have. We will respond faster than you thought."
      />

      <MathscriptForm />
      <ContactMoreData />
      <ContactMap />

      <img
        src={headquarters}
        alt="Headquarters of Mathscript Ltd or Mathscript j. d. o. o. in Zagreb, Savska cesta 41"
        draggable="false"
        className="headquarters-img"
      />

      <h1 className="bright-text">
        Thank you for visiting us. We are still at disposal.
      </h1>

      <Footer />
    </div>
  );
}

export default Contact;
