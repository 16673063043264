import React from "react";
import "./CitiesAffiliate.css";

function CitiesAffiliate() {
  return (
    <div className="cities-affiliate">
      <h1>Headquarters in Zagreb, soon in London and Stockholm</h1>
      <div className="ca-container">
        <div className="ca-container-img" data-city="ZAGREB"></div>
        <div
          className="ca-container-img"
          data-city="LONDON (by the end of this year)"
        ></div>
        <div
          className="ca-container-img"
          data-city="STOCKHOLM (by the end of this year)"
        ></div>
      </div>
    </div>
  );
}

export default CitiesAffiliate;
